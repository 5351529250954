<template>
  <v-card class="d-flex flex-column h-100">
    <v-card-title>
      <v-row>
        <v-col>
          <v-icon class="mr-1">mdi-beer</v-icon>
          <span>{{ user.person.company }}</span>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-subtitle>
      {{ user.codInt }} <br />
      {{ $t("listUser.formatAddress", user.billingAddress) }}
    </v-card-subtitle>

    <v-spacer />

    <v-card-actions class="justify-end">
      <v-btn outlined depressed color="secondary" @click="selectUser">
        {{ $t("listUser.userCard.loginBtn") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.list-user-container {
  .search-list-user {
    width: 400px;
  }
  .v-list-item.user-list-item:nth-child(odd) {
    background-color: var(--v-grey-lighten3);
  }
}
</style>

<script>
import backofficeService from "@/commons/service/backofficeService";
import { mapActions } from "vuex";

export default {
  name: "SsoUserdCard",
  props: {
    user: { type: Object, required: true }
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    async selectUser() {
      const result = await backofficeService.selectUser(this.user.userId);

      if (result.response && result.response.status == 0) {
        await this.loadCart();
        this.$router.push({ name: "ProfileUpdate" });
      }
    }
  }
};
</script>
