<template>
  <v-container class="list-user-container">
    <h2>{{ $t("listUser.title") }}</h2>
    <v-list class="sso-user-list">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="8" class="d-flex ">
            <v-form
              @submit.prevent="handleSearch"
              class="search-list-user  d-flex"
            >
              <v-text-field
                v-model="searchUsernameModel"
                outlined
                height="56px"
                label="Cerca cliente"
                hide-details
              />
              <v-btn height="56" depressed color="primary" type="submit">
                <v-icon>$search</v-icon>
              </v-btn>
            </v-form>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="d-flex">
            <v-select
              v-model="userPersonSorter"
              dense
              :items="sorters"
              @change="handleSortListUser"
              height="56"
              clearable
              placeholder="Ordina per"
              outlined
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <template v-if="userList && userList.length > 0">
        <v-list-item>
          <v-list-item-title>
            {{ $t("listUser.userFound", { totUser: pager.totItems }) }}
          </v-list-item-title>
        </v-list-item>

        <v-row class="ma-2">
          <v-col
            v-for="user in userList"
            :key="user.userId"
            cols="12"
            sm="6"
            md="4"
            class="d-flex flex-column pa-3"
          >
            <UserCard :user="user" />
          </v-col>
        </v-row>

        <!---->

        <v-pagination
          v-if="!loading && pager && pager.totPages > 1"
          :value="pageFilter"
          :length="pager.totPages"
          :totalVisible="$vuetify.breakpoint.xs ? 6 : 7"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination>
      </template>
      <v-list-item v-else-if="userList != null">
        <v-list-item-title>
          {{ $t("listUser.noUserFound") }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-container>
</template>
<style lang="scss">
.list-user-container {
  .search-list-user {
    width: 100%;
  }
  .v-list-item.user-list-item:nth-child(odd) {
    background-color: var(--v-grey-lighten3);
  }
  .filter-sort {
    height: 100px !important;
  }
  .v-input__icon {
    height: 36px !important;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import UserCard from "@/components/sso/UserCard.vue";
import backofficeService from "@/commons/service/backofficeService";
//import UserPersonFilter from "@/components/product/filters/UserPersonFilter.vue";

export default {
  name: "SsoUserdCard",
  components: {
    UserCard
    //  UserPersonFilter
  },
  data() {
    return {
      userList: null,
      searchUsernameModel: undefined,
      pager: {},
      pageFilter: 1,
      loading: false,
      userPersonSorter: undefined,
      sorters: null
    };
  },
  computed: {
    ...mapGetters({
      isSsoUser: "cart/isSsoUser"
    })
  },
  methods: {
    async reload() {
      const result = await backofficeService.searchUser(
        this.$route.query.q,
        /^\d+$/.test(this.$route.query.q) ? this.$route.query.q : null,
        this.$route.query.page,
        15,
        this.$route.query.sort
      );
      this.userList = result.data.users;
      this.pager = result.data.page;

      this.loading = false;
    },
    async selectUser(user) {
      const result = await backofficeService.selectUser(user.userId);

      if (result.response && result.response.status == 0) {
        await this.loadCart();
        this.$router.push({ name: "ProfileUpdate" });
      }
    },

    async searchListUser() {
      this.loading = true;
      const query = {
        ...this.$route.query,
        q: this.searchUsernameModel
      };

      this.$router.push({
        query
      });
      const result = await backofficeService.searchUser(
        this.searchUsernameModel,
        /^\d+$/.test(this.searchUsernameModel)
          ? this.searchUsernameModel
          : null,
        this.pageFilter,
        15,
        this.userPersonSorter
      );
      if (result && result.data && result.data.users) {
        this.userList = result.data.users;
        this.pager = result.data.page;
      }
      this.loading = false;
    },
    handleSortListUser(filter) {
      const query = {
        ...this.$route.query,
        page: 1,
        sort: filter
      };

      this.$router.push({
        query
      });
    },
    handleSearch() {
      const query = {
        ...this.$route.query,
        page: 1,
        sort: this.$route.query.sort,
        q: this.searchUsernameModel
      };

      this.$router.push({
        query
      });
    },
    handlePageFilter(newPage) {
      const query = {
        ...this.$route.query,
        page: newPage
      };
      this.pageFilter = newPage;
      this.$router.push({
        query
      });
      this.searchListUser();
    }
  },
  mounted() {
    this.pageFilter = parseInt(this.$route.query.page || 1);
    this.sorters = [
      {
        value: "cod_int_asc",
        text: "Codice Cliente crescente"
      },
      {
        value: "cod_int_desc",
        text: "Codice Cliente decrescente"
      },
      {
        value: "company_desc",
        text: "Ragione sociale Z-A"
      },
      {
        value: "company_asc",
        text: "Ragione sociale A-Z"
      }
    ];
    if (this.$route.query && this.$route.query.q) {
      this.searchUsernameModel = this.$route.query.q;
      this.userPersonSorter = this.$route.query.sort;
      this.reload();
    }
  },
  async created() {
    //await this.reload();
    //set selected values
  },
  watch: {
    "$route.query": function() {
      this.reload();
    }
  }
};
</script>
